/*******************************
        User Overrides
*******************************/
.ui.sidebar {
  #navigationContent {
    padding: 1em;
  }
  .accordion {
    color: @white;
    margin-top: 0;
    .accordion {
      padding-left: 1em !important;
    }
    .dropdown.icon {
      float: right;
    }
  }
  .main-navigation-accordion {
    & > .title {
      padding-top: 1em;
      padding-bottom: 1em;
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      .icon {
        margin-right: 0.5em;
      }
    }
    .inverted.vertical.menu .item {
      color: @white !important;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      font-size: 1rem;
    }
  }
}
