/*******************************
    User Variable Overrides
*******************************/
.item.disabled {
    opacity: 0.5;
}
.ui.relaxed.list:not(.horizontal)>.item:not(:last-child) {
    padding-bottom: .85em;
}

.ui.relaxed.list:not(.horizontal)>.item:not(:first-child) {
    padding-top: .85em;
}