/*******************************
         Site Overrides
*******************************/
.grid .primary.column {
  background-color: @primaryColor;
}
@media only screen and (max-width: @largestMobileScreen) {
  .ui.grid > .stackable.stackable.row > .column,
  .ui.stackable.grid > .column.grid > .column,
  .ui.stackable.grid > .column.row > .column,
  .ui.stackable.grid > .column:not(.row),
  .ui.stackable.grid > .row > .column,
  .ui.stackable.grid > .row > .wide.column,
  .ui.stackable.grid > .wide.column {
    padding: 1em 0 !important;
  }
}
