/*******************************
         Site Overrides
*******************************/
.ui.button {
  background: @white;
  box-shadow: @subtleShadow;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.mobile-navigation-bar {
  .ui.button {
    border: none;
  }
  .ui.buttons .button:first-child,
  .ui.buttons .button:last-child,
  .ui.upward.dropdown.button:not(.pointing):not(.floating).active {
    border-radius: 0;
    text-align: center;
  }
}

.list-copy-button {
  display: inline-block;
}

.ui.button.main-action-button {
  padding-top: 1.8em;
  padding-bottom: 1.8em;
  display: block;
  i.icon {
    display: block;
    text-align: center;
    width: auto;
    margin: 0 0 0.5em 0 !important;
  }
}

/* .ui.primary.button {
    color: @black;
    &:hover {
        color: @black;
        background: @yellowHover;
    }
}

.ui.secondary.button {
    color: @black;
    background: @offWhite;
    background: linear-gradient(@white 0%, @offWhite 100%);
    border: 1px solid @offWhite;
    &:hover {
        color: @black;
        background: @offWhite;
    }
} */
@media only screen and (max-width: @largestMobileScreen) {
  .ui.button.mobile-fluid {
    display: block;
    width: 100%;
    margin-bottom: 0.5em;
  }
}