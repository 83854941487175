/*******************************
         Site Overrides
*******************************/
.statistics-segments .ui.segment {
  padding-top: 3em;
  padding-bottom: 3em;
}

a.ui.segment {
  color: @textColor;
  border: 1px solid rgba(0, 0, 0, 0.08);
}


.ui.segment.mobile-person-segment {
  padding-bottom: 2.25em;
  h4.ui.header {
    font-size: 0.9em;
  }
  .ui.list .item {
    font-size: 0.9em;
  }
}

.draft-segment a{
  color: @textColor;
}

.ui.segment.wizard-footer {
  overflow: hidden;
}

@media only screen and (max-width: @largestMobileScreen) {
  .statistics-segments .ui.segment {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  }
  .ui[class*="very padded"].segment,
  .ui[class*="padded"].segment{
    padding: 1em;
  }

  .ui.segment.warning-segment {
    font-size: 85%;
  }

  .ui.padded.segment.settings-segment {
    font-size: 0.9em;
    
  }
}


/* Large Monitor */
@media only screen and (min-width: @largeMonitorBreakpoint) {
  .ui.padded.segment.permit-count-segment {
    padding-top: 5em;
    padding-bottom: 5em;
  }
}



/* 
@media only screen and (max-width: @computerBreakpoint) {
    .ui.very.padded.segment {
        padding: 1.5em;
        
    }
    
}

@media only screen and (max-width: @tabletBreakpoint) {
    .ui.very.padded.segment,
    .ui.raised.segments {
        padding: 1px;
        box-shadow: none;
        background: transparent;
        border: none;
    }
    .ui.raised.segments .segment{
        padding-left: 0;
        padding-right: 0;
    }


    .segments .segment .ui.stackable.grid> .column {
        padding: 1rem 0 !important;
    }

}
 */
