/*******************************
        Site Overrides
*******************************/
.ui.message.transaction-placement {
  background-color: @white;
  z-index: 1;
}



@media only screen and (max-width: @largestMobileScreen) {
  .ui.icon.message.transaction-placement {
    font-size: 0.8em;
    align-items: flex-start;
    .icon:not(.close) {
      font-size: 1.5em;
    }
    .ui.button.ui.dropdown {
      margin-bottom: 0.5em;
    }
  }
}

