/*******************************
         Site Overrides
*******************************/

.ui.label {
  padding: 0.5833em 1.1em;
}

/*--- primary ---*/
.ui.primary.labels .label,
.ui.primary.label {
  background-color: @primaryColor !important;
  border-color: @primaryColor !important;
  color: @white !important;
}

/*--- secondary ---*/
.ui.secondary.labels .label,
.ui.secondary.label {
  background-color: @secondaryColor !important;
  border-color: @secondaryColor !important;
  color: @white !important;
}

.ui.yellow.label {
  //color: @textColor !important;
}

.ui.right.floated.label {
  float: right;
}

.report-list-segment .ui.top.attached.label:first-child + :not(.attached) {
  margin-top: 1.2rem !important;
}
