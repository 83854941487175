/*******************************
    User Variable Overrides
*******************************/

/*Gate-app kuvakkeet etusivulla*/
.gate-apps .ui.cards>.card>.image{
    border: 3px solid @primaryColor !important;
    width: 6.5rem;
    height: 6.5rem;
    margin: 1rem auto 0;
    border-radius: 1000px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gate-apps .ui.cards>.card>.image>img{
    height: 50px;
}
