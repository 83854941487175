/*******************************
         Site Overrides
*******************************/
.yes-no-container  {
	display:flex;
}

.yes-no-container .yes-no-item-container {
	flex: 1;
	text-align: center;
}

.yes-no-container .yes-no-item-container input[type="radio"] {
    display: none;
}


.yes-no-container .yes-no-item-container input[type="radio"] + label {
	width: 100%;
	margin: 0;
	padding: 12px 18px;
	display: inline-block;
	border: 1px solid rgba(0,0,0,0.12);
	border-right: none;
	cursor:pointer;
	white-space: nowrap;
}

.yes-no-container .yes-no-item-container:first-child input[type="radio"] + label {
	border-radius: @absoluteBorderRadius 0 0 @absoluteBorderRadius;
}

.yes-no-container .yes-no-item-container:last-child input[type="radio"] + label {
	border-right: 1px solid rgba(0,0,0,0.12);
	border-radius: 0 @absoluteBorderRadius @absoluteBorderRadius 0;
}

.yes-no-container .yes-no-item-container label:hover {
     background:@offWhite;
}

.yes-no-container .yes-no-item-container input[type="radio"]:checked + label {
	box-shadow: inset 0 3px 5px -3px rgba(0,0,0,0.12), inset 3px 0 5px -3px rgba(0,0,0,0.12);
}

.yes-no-container .yes-no-item-container input[type="radio"] + label::before {
	font-family: "Font Awesome 5 Pro";
	margin-right: 8px;
	font-weight: 600;
}

.yes-no-container .yes-no-item-container input[value="yes"] + label::before {
	content: "\f00c";
	color: @green;
}

.yes-no-container .yes-no-item-container input[value="yes"]:checked + label {
    background: @green;
	color: @white;
}

.yes-no-container .yes-no-item-container input[value="yes"]:checked + label::before {
	color: @white;
}


.yes-no-container .yes-no-item-container input[value="no"] + label::before {
	content: "\f05e";
	color: @grey;
}
.yes-no-container .yes-no-item-container input[value="no"]:checked + label {
    background: @lightGrey;
	//color: @white;
}

.yes-no-container .yes-no-item-container input[value="no"]:checked + label::before {
	//color: @white;
}
 