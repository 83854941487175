/*******************************
        Site Overrides
*******************************/
.ui.menu.main-navigation {
  .site-logo {
    border-radius: 0 0 @absoluteBorderRadius @absoluteBorderRadius;
    margin-left: 1em;
    margin-bottom: -0.5em;
  }
  .item {
    padding: 1.5rem;
  }

  transition: background-color 0.2s ease;
}

/* Menu shadow */
.ui.shadow.menu {
  box-shadow: @subtleShadow;
}

/* Primary */
.ui.inverted.menu .primary.active.item,
.ui.inverted.primary.menu {
  background-color: @primaryColor;
}
.ui.inverted.primary.menu .item:before {
  background-color: @invertedColoredDividerBackground;
}
.ui.inverted.primary.menu .active.item {
  background-color: @invertedColoredActiveBackground !important;
}

.reports-menu.ui.menu {
  .item {
    padding-top: 1.25em;
    padding-bottom: 1.25em;
  }
}

.company-menu {
  .ui.dropdown>.text {
    max-width: 15vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.main-navigation .expandable_mobile_menu {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 46px;
  width: 0;
  height: calc(100vh - 46px);
  background-color: @primaryColor;
  display: none;

  .item {
    display: none;
    opacity: 0;
  }

  &.expanded {
    width: 100vw;
    display: block;
    .item {
      display: block;
      opacity: 1;
    }
    //Make company-dropdown stay on page in mobile view
    .dropdown:last-child .menu {
      left: 0 !important;
      max-width: 90vw;
      > .item {
        white-space: normal;
        max-width: 90vw;
      }
    }
  }
}

.only_mobile {
  display: none !important;
}

@media only screen and (max-width: @largestMobileScreen) {
  .only_desktop {
    display: none !important;
  }
  .only_mobile {
    display: inherit !important;
  }

  .ui.menu.main-navigation {
    .site-logo {
      font-size: 0.8em;
    }
    .item {
      padding-top: 0;
      padding-bottom: 0;
    }
    .expandable_mobile_menu {
      .item {
        padding-top: 1.2em;
        padding-bottom: 1.2em;
      }
    }
  }
}



/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: @computerBreakpoint) and (max-width: @largestSmallMonitor) {
  .ui.main-navigation.menu {
    font-size: 0.9em;
    .ui.site-logo.menu {
      font-size: 0.9em;
    }
    .item {
      padding: 1.25em 1em;
    }
  }
}
