/*******************************
         Site Overrides
*******************************/

h4.ui.header,
h5.ui.header {
  font-weight: @bold;
}

.ui.menu .primary.header.item {
  color: @primaryColor;
}

.ui.menu .secondary.header.item {
  color: @secondaryColor;
}

.inverted .ui.header .sub.header {
  color: rgba(255, 255, 255, 1);
}

h1.ui.header {
  .sub.header {
    color: @textColor;
  }
}

@media only screen and (max-width: @largestMobileScreen) {
  h1 {
    font-size: 1.5rem;
  }
  h1.ui.header {
    font-size: 1.5rem;
    .sub.header {
      font-size: 0.9rem;
    }
  }
  h2.ui.header {
    font-size: 1.4rem;
  }
  h3.ui.header {
    font-size: 1.2rem;
  }
}
