.topnav-desktop-force-flex {
    width: 100%;
    display: flex;
}

@media all and (min-width: 992px) {
    .topnav-desktop {
        display: flex;
    }
    .topnav-mobile {
        display: none !important;
    }
}

@media all and (max-width: 992px) {
    .topnav-mobile {
        display: block;
    }
    .topnav-desktop {
        display: none;
    }
}
