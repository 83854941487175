/*******************************
    User Variable Overrides
*******************************/
.ui.statistic>.value, 
.ui.statistics .statistic>.value {
    font-weight: 300;
}

.ui.statistic>.label, 
.ui.statistics .statistic>.label {
    font-weight: 400;
    font-size: 0.9em;
}

@media only screen and (max-width: @tabletBreakpoint) {
    .ui.statistic>.label, .ui.statistics .statistic>.label {
        font-size: 0.8em;
    }
}