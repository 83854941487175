/*******************************
         Site Overrides
*******************************/
body {
  //background: url('/img/background-carousel-corner.png') no-repeat bottom right;
  //margin-left: 140px;
  //margin-right: 20px;
  //margin-top: 20px;
  margin-bottom: 0;
  //background: @offWhite;
  //background: linear-gradient(to right, @offWhite 0%, @white 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.site-container {
  margin: 100px 1em 1em;
}

.cookie-consent ~ .site-container {
  padding-bottom: 200px;
}

.person-display {
  .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

img {
  max-width: 100%;
}

.ui.large.header.page-header {
  margin-top: 15px;
}

.content-container {
  flex: 1 1 auto;
  min-width: 0px;
  margin-left: 275px;
  padding: 3em;
}

.mobile-navigation-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  -webkit-box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  z-index: 100000;
  border-top: 1px solid #f3f4f5;
}
.mobile-navigation-bar .ui.icon.button,
.mobile-navigation-bar .ui.icon.buttons .button {
  padding: 1.4em 1em 1.4em;
}

.ui.grid .column.white {
  background-color: @white !important;
}

.luvat .ui.circular.label {
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.drop.zone {
  display: flex;
  align-items: center;
  border: 2px dashed @lightGrey;
  padding: 1rem;
  border-radius: @defaultBorderRadius*2;
  background: @offWhite;
  margin-bottom: 1em;
  .drop.text {
    padding-left: 1em;
    padding-right: 1em;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.success {
    background: @greenBackground;
    border-color: @lightGreen;
  }
  &.loading {
    background: @blueBackground;
    border-color: @lightBlue;
  }
  &.invalid {
    background: @yellowBackground;
    border-color: @lightYellow;
  }
  &.error {
    background: @redBackground;
    border-color: @lightRed;
  }
  .images-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
}
.uploaded-images {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  .uploaded-image {
    position: relative;
    display: inline-block;
    margin-right: 1em;
    .floating.ui.black.label {
      background: rgba(0, 0, 0, 0.7) !important;
    }
  }
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  .image-container {
    max-width: 135px;
    position: relative;
    margin: 0 10px 10px 0;
    .ui.button {
      position: absolute;
      top:-5px;
      right:-10px;
    }
  }
}

.ui.menu.home-tabs {
  margin-top: 5px;
}
.ui.segments.home-statistics {
  .ui.segment {
    flex: 1 1 0;
  }
  .home-statistic {
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    display: block;
    h3.ui.header {
      margin-bottom: 0;
      font-size: 1.6em;
    }
    h3.ui.huge.header {
      font-size: 2.8em;
    }
  }
}

.ui.segments.category-buttons {
  .ui.segment {
    flex: 1 1 0;
  }
  a {
    color: @textColor;
    font-size: 0.8em;
    font-weight: 600;
    display: block;
    padding: 0.7em 0;
    i.icon {
      font-size: 2.4em;
      font-weight: 300;
    }
  }
}

.permit-row {
  display: flex;
  color: @textColor;
  align-items: center;
  background-color: @white;
  padding: 1em;
  box-shadow: @subtleShadow;
  border-radius: @absoluteBorderRadius;
  margin-bottom: 0.5em;
  border: 1px solid rgba(0, 0, 0, 0.08);
  &:hover {
    color: @textColor;
  }
  .permit-header {
    flex: 1;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    font-weight: 600;
    .ui.label {
      margin-right: 0.5rem;
    }
  }

  .permit-meta {
    flex: 2;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    .permit-meta-item {
      flex: 1;
      margin-right: 1rem;
    }
  }
}

/* @media only screen and (max-width: @largestTabletScreen) {

  .mobile-header {
    display: flex;
  }

  .page-container {
    display: block;
  }

  .content-container {
    margin-left: 0;
    padding: 0.75em;
  }
  .sidebar-container {
    margin-top: 3em;
    transform: translateX(-300px);
    transition: all @defaultDuration @defaultEasing;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25),
                0 10px 10px rgba(0,0,0,0.22);
    &.active {
      transform: translateX(0);
    }
  }
} */
.ui.segment {
  display: block;
}

.show-kasittely {
  cursor: pointer;
}

.diary-entry {
  color: @textColor;
  .diary-entry-header {
    font-weight: normal;
    margin-bottom: 4px;
  }
  .meta {
    .meta-item {
      margin-right: 15px;
      display: inline-block;
    }
  }
}

.map-container {
  img {
    border-radius: @absoluteBorderRadius;
    box-shadow: @floatingShadow;
  }
}

.fullpage_loading_segment{
  height: 100vh;
}

#blockColorblindContent {
  display: none;
}

.money-form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  align-items: center;
  .item {
    flex: 1;
    font-size: 0.9rem;
  }
  .amount {
    flex: 1;
    font-size: 0.9rem;
    text-align: right;
    font-weight: bold;
  }
  .counter {
    display: flex;
    flex: 3;
  }
}
.ute-select-all {
  background: @offWhite;
}

.show-person-popup {
  cursor: pointer;
}



.ui.segment.old-user-notification {
  display: flex;
  color: @textColor;
  .image-container {
    margin-right: 1em;
  }
  .ui.header {
    margin: 0;
    .sub.header {
      color: @textColor;
    }
  }
}

.plan-related-persons {
  & > li {
    margin-bottom: 1em;
  }
}

.processing-permit-loader {
  margin-top: 50px;
}

//FIX SAFARI BUTTON STYLES
[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: none;
}

/* Semantic UI has these classes, however they're only applicable to*/
/* grids, containers, rows and columns.*/
/* plus, there isn't any `mobile hidden`, `X hidden` class.*/
/* this snippet is using the same class names and same approach*/
/* plus a bit more but to all elements.*/
/* see https://github.com/Semantic-Org/Semantic-UI/issues/1114*/

/* Mobile */
@media only screen and (max-width: @largestMobileScreen) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large screen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }
  body {
    margin: 0;
  }

  .site-container {
    margin-top: 70px;
  }

  .permit-row {
    display: block;
    .permit-header {
      flex: 0;
      margin-bottom: 0.5em;
    }
    .permit-meta {
      justify-content: space-between;
      .permit-meta-item {
        flex: auto;
      }
    }
  }
  .ui.grid.ute-grid {
    .ute-label {
      font-size: 85%;
    }
  }
  .ui.segment.old-user-notification {
    flex-direction: column;
    align-items: center;
    .image-container {
      width: 80px;
      margin: -40px 0 1em;
    }
    .ui.header {
      font-size: 1em;
      font-weight: bold;
      .sub.header {
        font-size: .9em;
      }
    }
  }
  .person-display {
    .name {
      & > span {
        max-width: 215px;
      }
    }
  }
}



/* Tablet / iPad Portrait */
@media only screen and (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {
  [class*="mobile only"]:not(.tablet),
  [class*="tablet hidden"],
  [class*="computer only"]:not(.tablet),
  [class*="large screen only"]:not(.tablet),
  [class*="widescreen only"]:not(.tablet),
  [class*="or lower hidden"]:not(.mobile) {
    display: none !important;
  }
  .permit-row {
    display: block;
  }
}

/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: @computerBreakpoint) and (max-width: @largestSmallMonitor) {
  [class*="mobile only"]:not(.computer),
  [class*="tablet only"]:not(.computer),
  [class*="computer hidden"],
  [class*="large screen only"]:not(.computer),
  [class*="widescreen only"]:not(.computer),
  [class*="or lower hidden"]:not(.tablet):not(.mobile) {
    display: none !important;
  }
  .permit-row {
    display: block;
  }
}

/* Large Monitor */
@media only screen and (min-width: @largeMonitorBreakpoint) and (max-width: @largestLargeMonitor) {
  [class*="mobile only"]:not([class*="large screen"]),
  [class*="tablet only"]:not([class*="large screen"]),
  [class*="computer only"]:not([class*="large screen"]),
  [class*="large screen hidden"],
  [class*="widescreen only"]:not([class*="large screen"]),
  [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Widescreen Monitor */
@media only screen and (min-width: 1920px) {
  [class*="mobile only"]:not([class*="widescreen"]),
  [class*="tablet only"]:not([class*="widescreen"]),
  [class*="computer only"]:not([class*="widescreen"]),
  [class*="large screen only"]:not([class*="widescreen"]),
  [class*="widescreen hidden"],
  [class*="widescreen or lower hidden"] {
    display: none !important;
  }
}

// file input component
.image-dropzone {
  display: flex;
  align-items: center;
  border: 2px dashed @lightGrey;
  padding: 1rem;
  border-radius: @defaultBorderRadius*2;
  background: @offWhite;
  margin-bottom: 1em;

  transition: all 0.3s ease-in;

  &.active {
      background-color: rgb(150,200,255);
      border-color: rgb(120,160,205);
  }
}

.image-dropzone-text {
  padding-left: 1em;
  padding-right: 1em;
}

/* payment provider list */
.payment-group-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5em;

    form {
        width: 25%;
        padding: 0.5em;
    }
}

.payment-group-provider-btn {
    width: 100%;
    background-color: transparent;
    border: thin solid gray;
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
}

.wrap-newline {
  white-space: pre-wrap;
}
