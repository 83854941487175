/*******************************
         Site Overrides
*******************************/

@media only screen and (max-width: @largestMobileScreen) {

  //Hide all steps but active in mobile screens
  .ui.steps.wizard-steps {
    border:none;
    .step {
      display: none;
    }
    .step.active {
      display: block;
      padding-left: 0;
      padding-bottom: 0;
      background: transparent;
      border:none;
      border-top: 1px solid @strongTransparentBlack;
      border-radius: 0;
      .content {
        text-align: left;
      }
      
    }
  }
}