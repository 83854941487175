/*******************************
        User Overrides
*******************************/
.ui.dropdown.person-select .menu > .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
  .ui.button {
    margin-right: 0;
  }
}

@media only screen and (max-width: @largestMobileScreen) {
  .ui.search.dropdown .menu {
      max-height: 11rem;
  }
}